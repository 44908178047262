<template>
  <v-container>
    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <form @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.shop") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-autocomplete

                v-model="form.store_id"
                :items="shopList"
                :error-messages="shopErrors"
                @input="$v.form.store_id.$touch()"
                @blur="$v.form.store_id.$touch()"
                required
                outlined
                item-text="store_name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.product-name") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                v-model="form.item_name"
                :error-messages="productNameErrors"
                @input="$v.form.item_name.$touch()"
                @blur="$v.form.item_name.$touch()"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label lable-class">{{
                $t("message.category")
              }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-autocomplete
                v-model="form.category"
                :items="itemListCategory"
                item-text="category_name"
                item-value="id"
                outlined
                required
                :error-messages="categoryErrors"
                @input="$v.form.category.$touch()"
                @blur="$v.form.category.$touch()"
                v-on:change="checkingBulk"
                auto-select-first
              >
                <template v-slot:item="{item}">
                  <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                </template>
              </v-autocomplete>
            </v-col>
            <div v-if="form.is_bulk === 1" class="mb-3">
              <v-row>
                <v-col cols="12" md="2" xs="2" class="label-class ps-3 m-0">
                  <span class="label lable-class ">{{
                    $t("message.max-qty-purchase")
                  }}</span>
                </v-col>
                <v-col cols="12" md="10" xs="2" class="input-class ">
                  <v-text-field
                    outlined
                    required
                    type="number"
                    v-model="form.maxQty"
                    :error-messages="maxQtyErrors"
                    @input="$v.form.maxQty.$touch()"
                    @blur="$v.form.maxQty.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.price") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                :error-messages="priceErrors"
                @input="$v.form.price.$touch()"
                @blur="$v.form.price.$touch()"
                v-model="form.price"
                type="number"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.tax") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                :error-messages="taxErrors"
                @input="$v.form.tax.$touch()"
                @blur="$v.form.tax.$touch()"
                v-model="form.tax"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.item-code") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                v-model="form.item_code"
                outlined
                required
              ></v-text-field>
            </v-col>
            <div>
              <div class="row">
                <v-col cols="12" md="2" xs="2" class="label-class">
                  <span class="label">{{ $t("message.initial-stock") }}</span>
                </v-col>
                <v-col cols="12" md="10" xs="2" class="input-class">
                  <v-text-field
                    type="number"
                    v-model="form.initial_stock"
                    required
                    outlined
                    :error-messages="initial_stockErrors"
                    @input="$v.form.initial_stock.$touch()"
                    @blur="$v.form.initial_stock.$touch()"
                  ></v-text-field>
                </v-col>
              </div>
            </div>

            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.width") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                type="number"
                v-model="form.width"
                label=""
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.length") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                type="number"
                v-model="form.length"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.height") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                type="number"
                v-model="form.height"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.weight") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                type="number"
                v-model="form.weight"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.label-tags") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                type="text"
                v-model="form.tag"
                outlined
              ></v-text-field>
            </v-col>
            <div>
              <div class="row">
                <v-col cols="12" md="2" xs="2" class="label-class">
                  <span class="label">{{ $t("message.status") }}</span>
                </v-col>
                <v-col cols="12" md="10" xs="2" class="input-class">
                  <v-select
                    type="number"
                    v-model="form.is_active"
                    :items="is_active"
                    outlined
                    item-text="is_active"
                    item-value="id"
                  ></v-select>
                </v-col>
              </div>
            </div>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.reset-flag") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-select
                type="number"
                v-model="form.flag_reset"
                :items="resetFlag"
                :error-messages="resetFlagErrors"
                @input="$v.form.flag_reset.$touch()"
                @blur="$v.form.flag_reset.$touch()"
                required
                outlined
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.label-tax_rate") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
              type="number"
              v-model="form.tax_rate"
              :error-messages="tax_rateErrors"
              @input="$v.form.tax_rate.$touch()"
              @blur="$v.form.tax_rate.$touch()"
              required
              outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.label-Tax_process_category") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-select
                type="number"
                v-model="form.tax_process_category"
                :items="taxProcessCategory"
                :error-messages="taxCategoryErrors"
                @input="$v.form.tax_process_category.$touch()"
                @blur="$v.form.tax_process_category.$touch()"
                required
                outlined
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.label-locker") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-select
                type="number"
                v-model="form.locker"
                :items="isLocker"
                :error-messages="lockerErrors"
                @input="$v.form.locker.$touch()"
                @blur="$v.form.locker.$touch()"
                required
                outlined
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.description") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-textarea
                v-model="form.description"
                outlined
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.specification") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-textarea
                v-model="form.specification"
                outlined
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.images") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                class="file-input-class"
                type="file"
                ref="fileImg"
                v-model="form.images"
                @change="upload"
                accept="image/*"
                outlined
                id="file-input"
                required
                :disabled="uploadImg.length == 10"
              >
              </v-file-input>
              <p style="font-size: 12px; color: red; margin-top: -25px; margin-left: 28px">{{ $t("message.label-max-img-upload") }}</p>
              <v-row v-if="isLoadingImg" class="fill-height ma-0">
                <v-progress-circular
                  indeterminate
                  color="blue"
                ></v-progress-circular>
              </v-row>
            </v-col>
            <template>
              <v-container class="d-flex justify-end" >
                <v-row class="mb-6" no-gutters justify="start">
                  <v-col cols="2"></v-col>
                  <v-col v-for="(img, i) in uploadImg" :key="i">
                    <v-icon @click.prevent="deleteImg(img.id, i)">
                      {{ closeImg }}
                    </v-icon>
                    <v-img
                      :lazy-src="img.image_url"
                      :src="img.image_url"
                      max-height="150px"
                      max-width="180px"
                      class=""
                      width="150"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-row>
          <div class="d-flex flex-row-reverse mt-3 me-0" tile>
            <div class="m-2">
              <v-btn color="primary" type="submit" @click.prevent="submitForm">
                {{ $t("message.submit-button") }}
              </v-btn>
            </div>
            <div>
              <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                {{ $t("message.back-button") }}
              </v-btn>
            </div>
          </div>
        </form>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  minValue,
  decimal
} from "vuelidate/lib/validators";
import { mdiCloseThick } from "@mdi/js";
export default {
  name: "FormGroup",
  mixins: [validationMixin],
  props: {
    itemDetail: Object,
  },
  validations: {
    form: {
      item_name: { required, minLength: minLength(10) },
      store_id: { required },
      category: { required },
      price: { required, numeric },
      maxQty: { required, numeric },
      tax: { required, decimal },
      initial_stock: { required },
      description: { required, minLength: minLength(10) },
      specification: { required, minLength: minLength(10) },
      images: { required },
      is_active: { required },
      item_code: { required },
      weight: { required, numeric },
      flag_reset: { required },
      width: { required, numeric },
      length: { required, numeric },
      height: { required, numeric },
      tax_rate: { required, numeric },
      tax_process_category: { required },
      locker: { required }
    },
  },

  data: () => ({
    closeImg: mdiCloseThick,
    errorValues: {},
    findIsBulk: null,
    isEdited: -1,
    isLoading: false,
    isLoadingImg: false,
    is_active: [
      { id: 0, is_active: '非公開' },
      { id: 1, is_active: '公開' },
    ],
    form: {
      item_name: "",
      tag: "",
      category: "",
      price: "",
      is_bulk: "",
      tax: "",
      initial_stock: "",
      description: "",
      specification: "",
      item_code: "",
      is_active: "",
      images: null,
      store_id: "",
      weight: "",
      width: "",
      length: "",
      height: "",
      flag_reset: "",
      tax_rate: "",
      tax_process_category: "",
      locker: "",
      minimum_stock: 100,
      minimum_order: 1,
      // minQty: "",
      maxQty: "",
    },
    message: {},
  }),

  computed: {
    resetFlagErrors() {
      const errors = [];
      if (!this.$v.form.flag_reset.$dirty) return errors;
      !this.$v.form.flag_reset.required &&
        errors.push(this.$t("message.err-msg-reset-flag"));
      return errors;
    },
    tax_rateErrors() {
      const errors = [];
      if (!this.$v.form.tax_rate.$dirty) return errors;
      !this.$v.form.tax_rate.numeric && errors.push(this.$t("message.err-msg-tax_rate-numeric"));
      !this.$v.form.tax_rate.required &&
        errors.push(this.$t("message.err-msg-tax_rate-required"));
      if(this.form.tax_rate < 0) {
        errors.push(this.$t("message.err-msg-tax_rate-not-zero"))
      }
      return errors;
    },
    taxCategoryErrors() {
      const errors = [];
      if (!this.$v.form.tax_process_category.$dirty) return errors;
      !this.$v.form.tax_process_category.required &&
        errors.push(this.$t("message.err-msg-taxCategory"));
      return errors;
    },
    lockerErrors() {
      const errors = [];
      if (!this.$v.form.locker.$dirty) return errors;
      !this.$v.form.locker.required &&
        errors.push(this.$t("message.err-msg-locker"));
      return errors;
    },
    shopErrors() {
      const errors = [];
      if (!this.$v.form.store_id.$dirty) return errors;
      !this.$v.form.store_id.required &&
        errors.push(this.$t("message.err-msg-shop"));
      return errors;
    },
    weightErrors() {
      const errors = [];
      if (!this.$v.form.weight.$dirty) return errors;
      !this.$v.form.weight.numeric && errors.push("Weight must be number");
      !this.$v.form.weight.required &&
        errors.push(this.$t("message.err-msg-weight"));
      return errors;
    },
    lengthErrors() {
      const errors = [];
      if (!this.$v.form.length.$dirty) return errors;
      !this.$v.form.length.required &&
        errors.push(this.$t("message.err-msg-length"));
      return errors;
    },
    heightErrors() {
      const errors = [];
      if (!this.$v.form.height.$dirty) return errors;
      !this.$v.form.height.required &&
        errors.push(this.$t("message.err-msg-height"));
      return errors;
    },
    widthErrors() {
      const errors = [];
      if (!this.$v.form.width.$dirty) return errors;
      !this.$v.form.width.required &&
        errors.push(this.$t("message.err-msg-width"));
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.form.category.$dirty) return errors;
      !this.$v.form.category.required &&
        errors.push(this.$t("message.err-msg-category"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      !this.$v.form.price.numeric &&
        errors.push(this.$t("message.err-msg-price-num"));
      !this.$v.form.price.required &&
        errors.push(this.$t("message.err-msg-price"));
      return errors;
    },
    maxQtyErrors() {
      const errors = [];
      if (!this.$v.form.maxQty.$dirty) return errors;
      !this.$v.form.maxQty.numeric &&
        errors.push(this.$t("message.err-msg-max-purchase-numeric"));
      !this.$v.form.maxQty.required &&
        errors.push(this.$t("message.err-msg-max-purchase-req"));
      return errors;
    },
    taxErrors() {
      const errors = [];
      if (!this.$v.form.tax.$dirty) return errors;
      !this.$v.form.tax.decimal &&
        errors.push(this.$t("message.err-msg-tax-num"));
      !this.$v.form.tax.required && errors.push(this.$t("message.err-msg-tax"));
      return errors;
    },
    initial_stockErrors() {
      const errors = [];
      if (!this.$v.form.initial_stock.$dirty) return errors;
      !this.$v.form.initial_stock.required &&
        errors.push(this.$t("message.err-msg-stock"));
      return errors;
    },
    productNameErrors() {
      const errors = [];
      if (!this.$v.form.item_name.$dirty) return errors;
      !this.$v.form.item_name.required &&
        errors.push(this.$t("message.err-msg-item-name"));
      return errors;
    },
    item_codeErrors() {
      const errors = [];
      if (!this.$v.form.item_code.$dirty) return errors;
      !this.$v.form.item_code.required &&
        errors.push(this.$t("message.err-msg-item-code"));
      return errors;
    },
    specificationErrors() {
      const errors = [];
      if (!this.$v.form.resetFlag.$dirty) return errors;
      !this.$v.form.resetFlag.required &&
        errors.push(this.$t("message.err-msg-specification"));
      return errors;
    },

    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    itemListCategory() {
      return this.$store.state.itemList_module.itemListCategory;
    },
    uploadImg() {
      return this.$store.state.uploadImage_module.resLink;
    },
    resetFlag(){
      return [
        {text: this.$t("message.yes"), value: 1},
        {text: this.$t("message.no"), value: 0}
      ]
    },
    taxProcessCategory() {
      return [
        { text: "外税", value: 1 },
        { text: "内税", value: 2 },
        { text: "非課税", value: 3 }
      ]
    },
    isLocker() {
      return [
        { text: "可", value: 1 },
        { text: "不可", value: 0 }
      ]
    }
  },
  methods: {
    checkingBulk(val) {
      this.itemListCategory.forEach((el) => {
        if (el.id === val) {
          this.form.is_bulk = el.is_bulk;
        }
      });
      return this.form.is_bulk;
    },
    submitForm() {
      this.$v.$touch();
      let payload = {
        item_name: this.form.item_name,
        category_id: this.form.category,
        price: this.form.price,
        tax: this.form.tax,
        initial_stock: parseInt(this.form.initial_stock),
        description: this.form.description,
        specification: this.form.specification,
        store_id: this.form.store_id,
        item_code: this.form.item_code,
        images: this.uploadImg,
        flag_reset: this.form.flag_reset == undefined || null ? "" : this.form.flag_reset,
        weight: this.form.weight,
        width: this.form.width,
        length: this.form.length,
        height: this.form.height,
        tag: this.form.tag,
        minimum_stock: this.form.minimum_stock,
        minimum_order: this.form.minimum_order,
        is_active: this.form.is_active,
        tax_rate: this.form.tax_rate,
        tax_category: this.form.tax_process_category,
        is_locker: this.form.locker,
        // min_qty: this.form.minQty,
        max_qty: this.form.maxQty,
      };
      this.$emit("on-submit", payload);
    },
    fetchShopList() {
      return this.$store.dispatch("shop_module/fetchShopAll");
    },
    fetchItemListCategory() {
      return this.$store.dispatch("itemList_module/fetchItemListCategory");
    },
    async upload(event) {
      if (this.form.images != null) {
        await this.$store.dispatch("uploadImage_module/uploadImg", {
          images: this.form.images,
          path: "items",
        });
        // let uploadImg = await this.$store.state.uploadImage_module.resLink;
        // console.log(uploadImg.length, 'images')
      }
    },
    deleteImg(imageId, i) {
      if (this.isEdited > -1) {
        return this.$store.dispatch("uploadImage_module/deleteImgItem", {
          imgId: imageId,
          item_id: this.$route.params.id,
          imgIndex: i,
        });
      } else {
        return this.$store.dispatch("uploadImage_module/deleteStateItem", {
          index: i,
        });
      }
    },
    getOneItem() {
      let id = this.$route.params.id;
      if (id) {
        this.isEdited = id;
      } else {
        this.isEdited = -1;
      }
      if (this.isEdited > -1) {
        this.form = Object.assign(this.itemDetail, this.form);
      }
    },
  },
  created() {
    this.getOneItem();
    this.fetchShopList();
    this.fetchItemListCategory();
  },
  mounted() {
    this.$store.dispatch("uploadImage_module/clearImg");
  },
};
</script>

<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
