<template>
  <v-container>
    <h3>{{ $t("message.edit-item-title") }}</h3>

    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <FormGroup :itemDetail="editItem" @on-submit="updateItem" />
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import FormGroup from "../components/FormGroup.vue";

export default {
  name: "EditItem",
  components: { FormGroup },

  data: () => ({
    dataErr: "",
    errMsg: "",
    editItem: {
      item_name: "",
      category: "",
      price: "",
      tax: "",
      initial_stock: "",
      description: "",
      specification: "",
      item_code: "",
      store_id: "",
      weight: 0,
      is_active: "",
      tag: "",
      width: 0,
      length: 0,
      height: 0,
      minimum_stock: 10,
      flag_reset: "",
      tax_rate: "",
      tax_process_category: "",
      locker: "",
      minimum_order: 1,
      is_active: 1,
      is_bulk: "",
      minQty: "",
      maxQty: "",
      images: [],
    },
    message: {},
    images: null,
  }),
  computed: {},

  methods: {
    updateItem(itemVal) {
      this.message.title = this.$t("message.title-updateItem-success-message");
      this.$store.dispatch("itemList_module/updateListItem", {
        id: this.$route.params.id,
        data: itemVal,
        message: {
          title: this.message.title,
        },
      });
    },
    async dispatchItemDetail() {
      const id = this.$route.params.id;
      await this.$store.dispatch("itemList_module/fetchItemListDetail", id);
      let itemDetail = this.$store.state.itemList_module.itemDetail;
      this.$store.dispatch("uploadImage_module/setImg", itemDetail.images);

      this.editItem.tag = itemDetail.tag
      this.editItem.item_name = itemDetail.item_name;
      this.editItem.category = itemDetail.category_id;
      this.editItem.price = itemDetail.price;
      this.editItem.tax = itemDetail.tax;
      this.editItem.price = itemDetail.price;
      this.editItem.flag_reset = itemDetail.flag_reset;
      this.editItem.images = itemDetail.images; 
      this.editItem.initial_stock = parseInt(itemDetail.stock_beginning);
      this.editItem.width = parseInt(itemDetail.width);
      this.editItem.length = parseInt(itemDetail.length);
      this.editItem.description = itemDetail.description;
      this.editItem.is_active = itemDetail.is_active;
      this.editItem.item_code = itemDetail.item_code;
      this.editItem.specification = itemDetail.specification;
      this.editItem.weight = parseInt(itemDetail.weight);
      this.editItem.store_id = itemDetail.store_id;
      this.editItem.height = itemDetail.height;
      this.editItem.minimum_order = parseInt(itemDetail.minimum_order) == 0 ? 1 : parseInt(itemDetail.minimum_order);
      this.editItem.minimum_stock = parseInt(itemDetail.minimum_stock);
      this.editItem.is_active = itemDetail.is_active
      this.editItem.is_bulk = itemDetail.is_bulk
      this.editItem.minQty = itemDetail.min_qty
      this.editItem.maxQty = itemDetail.max_qty
      this.editItem.tax_rate = itemDetail.tax_rate
      this.editItem.tax_process_category = itemDetail.tax_category
      this.editItem.locker = itemDetail.is_locker
    },
  },
  beforeMount() {
    this.dispatchItemDetail();
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
